import "../styles/about.css"
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useEffect } from "react"


function Donate() {
    useEffect(() => {
        const app = document.querySelector(".App");
        app.style.overflowY = "hidden"
    }, [])
    return (<>
        <Header />
        <form className="donate" action="https://www.paypal.com/donate" method="post" target="_blank">
            <input type="hidden" name="hosted_button_id" value="TMTP4FSRT223E" />
            <button className="btn" >DONATE</button>
        </form>
    </>
    )
}


export default Donate