import "../styles/header.css"
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";


function Header() {
    const [menuDrop, setMenuDrop] = useState(false)
    function changeDimensions() {
        const app = document.querySelector(".App")
        app.style.minHeight = `${window.innerHeight}px`
        app.style.minWidth = `${window.innerWidth}px`
    }

    useEffect(() => {
        changeDimensions()
    }, [])

    window.addEventListener("resize", (e) => {
        changeDimensions()

    });

    useEffect(() => {
        const menuEl = document.querySelector(".menu");
        menuEl.addEventListener("click", toggleMenu)
    }, [])

    function linkClicked() {
        setMenuDrop(false)
    }

    function toggleMenu() {
        setMenuDrop((prevMenuDrop) => (!prevMenuDrop))
    }

    return (
        <header>
            <h2 className="menu" style={{ backgroundColor: menuDrop && "black", color: menuDrop && "white" }}>MENU</h2>
            {menuDrop && <div className="menu-content">
                <Link to="/about">
                    <p className="link menu-link-text" onClick={linkClicked}>ABOUT</p>
                </Link>
                <Link to="/upcoming">
                    <p className="link menu-link-text" onClick={linkClicked}>UPCOMING</p>
                </Link>
                <Link to="/media">
                    <p className="link menu-link-text" onClick={linkClicked}>MEDIA</p>
                </Link>
                <Link to="/artists">
                    <p className="link menu-link-text" onClick={linkClicked}>ARTISTS</p>
                </Link>
                <Link to="/donate">
                    <p className="link menu-link-text" onClick={linkClicked}>DONATE</p>
                </Link>
                {/* <Link to="/funders">
                    <p className="link menu-link-text" onClick={linkClicked}>FUNDERS</p>
                </Link> */}
                <Link to="/contact">
                    <p className="link menu-link-text" onClick={linkClicked}>CONTACT</p>
                </Link>
            </div>}
        </header>
    )
}


export default Header