import "../styles/upcoming.css"
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useEffect } from "react"


function Upcoming() {
    useEffect(() => {
        const app = document.querySelector(".App");
        app.style.overflowY = "hidden"
        function handleResize() {
            if (window.innerWidth / window.innerHeight > 1.5) {
                app.style.overflowY = "visible"
            }
        }
        window.onresize = handleResize
        handleResize()
    }, [])
    return (<>
        <Header />
        <div className="upcoming-main">
            {/* <img className="upcoming-main-img" src="./assets/pngs/upcoming2.png" /> */}

            <div className="text-ctr upcoming-page-title"><h2 className="text-left">UPCOMING<br />PERFORMANCES</h2></div>
            {/* <div className="upcoming-right-div text-ctr text-body"><h2 className="text-right"><span className="diagonal">FUTURE PASTORALE</span><br />FEBRUARY 24TH AT 7:30 PM<br />CLAUDE VIVIER<br />OJIKAWA<br />A PATH CONNECTING<br />THE IMAGINED<br />PASTORAL EXPANSES<br />OF HISTORY AND<br />THE FAR FUTURE<br />TICKETS ARE <span className="diagonal">HERE</span></h2></div> */}
            <div className="upcoming-content">
                <div className="rotate7 text-ctr upcoming-top"><h2 className="text-right"><span className="diagonal">BEING PASCAL DUSAPIN</span><br />APRIL 6TH AT 730 PM<br />A PALINDROMIC PORTRAIT<br />OF PASCAL.<br />ELECTRIFYING WORKS FOR<br />VOICES|CELLO|CLARINET<br />TICKETS ARE <a target="_blank" href="https://www.eventbrite.ca/e/being-pascal-dusapin-tickets-860900916817"><span className="diagonal">HERE</span></a></h2></div>
                <div className="center-div text-ctr"><h2 className="text-left"><span className="diagonal">BABES IN TOYLAND</span><br />SEPTEMBER 12TH AT 730 PM<br />THE RINGING SOUNDS<br />OF A SILVER BELL<br />AND WHISPERED RHYME<br />ANNOUNCE YOUR ENTRY<br />TO THE OTHER-WORLD<br />STEP INTO THE LAND OF<br />THE ABSURD<br /> TICKETS ARE <a href="https://www.eventbrite.ca/e/babes-in-toyland-tickets-878655190327" target="_blank"><span className="diagonal">HERE</span></a></h2></div>
            </div>

        </div>
    </>
    )
}


export default Upcoming