import "../styles/landing.css"
import Footer from './Footer';


function ComingSoon() {
    return (<>
        <div className="landing-main">
            <div className="text-ctr rotate7">
                <div className="landing-div-1">
                    <h2 className="text-left"><span className="landing-the">THE</span><br />HAPPENSTANCERS</h2>
                </div>
                <div className="landing-div-2">
                    <h2 className="">CHAMBER MUSIC AND THEATRE<br />AT THE HORIZON-LINE</h2>
                </div>
            </div>
        </div>
    </>
    )
}


export default ComingSoon