import "../styles/artists.css"
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useEffect } from "react"


function Artists() {
    useEffect(() => {
        const app = document.querySelector(".App");
        app.style.overflowY = "hidden"
        function handleResize() {
            if (window.innerWidth / window.innerHeight > 1.5) {
                app.style.overflowY = "visible"
            }
        }
        window.onresize = handleResize
        handleResize()
    }, [])
    return (<>
        <Header />
        <div className="artists-main">
            <div className="text-right artists-page-title"><h2>23.24 SEASON ARTISTS</h2></div>
            <div className="text-ctr text-right rotate12 artists-1"><h2>BRAD CHERWIN. <span className="desktop-only">CLARINET. </span>ARTISTIC DIRECTOR<br />HOI TONG KEUNG. PRODUCTION MANAGER<br />SIMON RIVARD. CONDUCTOR<br />BILLY WONG. LIGHTING DESIGN</h2></div>
            <div className="artists-2 text-ctr text-left"><h2>HEE-SOO YOON. VIOLIN<br />KATYA POPLYANSKY. VIOLIN<br />PETER EOM. CELLO</h2></div>
            <div className="artists-3 text-right text-ctr"><h2>ALEH REMEZAU. OBOE<br />LOUIS PINO. PERCUSSION<br />BEVIS NG. PERCUSSION</h2></div>
            <div className="artists-4 text-left text-ctr rotate-5"><h2 >DANIKA LOREN. VOICE<br />ELIZABETH POLESE. VOICE<br />HILLARY JEAN YOUNG. VOICE<br />REILLY NELSON. VOICE</h2></div>
            <div className="artists-5 text-left text-ctr rotate7"><h2>JEAN-LUC THERRIEN. PIANO<br />KEVIN AHFAT. PIANO</h2></div>
        </div>
    </>
    )
}


export default Artists