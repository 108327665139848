import "../styles/about.css"
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useEffect } from "react"


function About() {
    useEffect(() => {
        const app = document.querySelector(".App");
        app.style.overflowY = "hidden"
        function handleResize() {
            if (window.innerWidth / window.innerHeight > 1.5) {
                app.style.overflowY = "visible"
            }
        }
        window.onresize = handleResize
        handleResize()
    }, [])

    return (<>
        <Header />
        <div className="about-main">
            <img className="about-main-img" src="./assets/pngs/about2.png" />
            {/* <div className="text-ctr about-page-title"><h2 className="text-right">THE HAPPENSTANCERS<br />IS AN ENSEMBLE<br />OF MUSICIANS<br />IN TORONTO</h2></div>
            <div className="text-ctr rotate-15 about-page-top"><h2 className="text-left">THE HAPPENSTANCERS<br />IS A LABORATORY<br />FOR EXPERIMENTATION<br />WITH THE FORM<br />AND FUNCTION<br />OF CLASSICAL MUSIC<br />[WHATEVER THAT IS]</h2></div>
            <div className="center-div text-ctr"><h2 className="text-left">THE HAPPENSTANCERS<br />IS A GROUP OF ARTISTS<br />WALKING<br />NEW PATHWAYS<br /></h2></div> */}
        </div>
    </>
    )
}


export default About