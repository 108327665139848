import { Route, Router, Routes, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import About from './pages/About';
import Upcoming from './pages/Upcoming';
import Media from './pages/Media';
import Donate from './pages/Donate';
import Funders from './pages/Funders';
import Contact from './pages/Contact';
import Landing from './pages/Landing';
import Artists from './pages/Artists';
import Header from './components/Header';
import Footer from './components/Footer';
import './App.css';

const bgColors = ["#149600", "#f702a5", "#ff3500", "#fcc500", "#ffffff"]
function changeDimensions() {
  const app = document.querySelector(".App")
  app.style.minHeight = `${window.innerHeight}px`
  app.style.minWidth = `${window.innerWidth}px`
}

function randomBg() {
  const app = document.querySelector(".App")
  app.style.backgroundColor = bgColors[Math.round(Math.random() * bgColors.length)]
  console.log(app.style.backgroundColor)
}

function App() {
  const location = useLocation()
  useEffect(() => {
    changeDimensions()
    randomBg()
  }, [])
  useEffect(() => {
    randomBg()
  }, [location])


  window.addEventListener("resize", (e) => {
    changeDimensions()
  });

  return (
    <div className="App">
      {/* <Header /> */}
      <main>
        <Routes>
          <Route exact path="/" element={<Landing />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/upcoming" element={<Upcoming />} />
          <Route exact path="/media" element={<Media />} />
          <Route exact path="/artists" element={<Artists />} />
          <Route exact path="/donate" element={<Donate />} />
          <Route exact path="/funders" element={<Funders />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </main>
      {/* <Footer /> */}
    </div>
  );
}

export default App;
